var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tree-accordion-item-arrow-expander" },
    [
      _c("IconArrowRoundedDropdown", {
        attrs: { width: 20, height: 20, state: _vm.iconState }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }