var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ListElement",
    {
      attrs: { size: _vm.size, disabled: _vm.item.disabled },
      nativeOn: {
        click: function($event) {
          $event.preventDefault()
          return _vm.onSelect.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "tree-accordion-item" },
        [
          _vm._l(_vm.positions, function(position) {
            return _c("TreeAccordionItemNode", {
              key: position,
              attrs: {
                size: _vm.size,
                "is-expander-visible":
                  _vm.item.childrenLength > 0 && position === _vm.positions,
                "is-expanded": _vm.item.expanded
              },
              on: { expand: _vm.onExpand }
            })
          }),
          _vm._v(" "),
          _vm.multiselect
            ? _c(
                "div",
                { class: _vm.treeAccordionItemActionClasses },
                [
                  _c("CheckBox", {
                    attrs: { value: _vm.selected, disabled: _vm.item.disabled }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "ListElementDescription",
            { attrs: { "word-break": "none" } },
            [
              _c("ListElementTitle", {
                attrs: {
                  size: _vm.size,
                  title: _vm.item.label || _vm.item.code
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.selectedNodes.all > 0
            ? _c(
                "ListElementAction",
                [
                  _c("TreeAccordionItemBadge", {
                    attrs: {
                      all: _vm.selectedNodes.all,
                      selected: _vm.selectedNodes.selected
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }