var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ExpandingList", {
    attrs: { items: _vm.items, expanded: _vm.expanded },
    scopedSlots: _vm._u(
      [
        {
          key: "header",
          fn: function() {
            return [
              !_vm.isPlaceholderVisible
                ? _c(
                    "div",
                    { staticClass: "tree-accordion-header" },
                    [
                      _vm._t("prependHeader"),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "tree-accordion-header__search" },
                        [
                          _vm.searchable
                            ? _c("SelectListSearch", {
                                attrs: {
                                  placeholder: _vm.searchPlaceholder,
                                  value: _vm.searchValue,
                                  size: _vm.size
                                },
                                on: { input: _vm.onSearch }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._t("appendSearchHeader")
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm._t("appendHeader")
                    ],
                    2
                  )
                : _vm._e()
            ]
          },
          proxy: true
        },
        {
          key: "body",
          fn: function() {
            return [
              _vm._t("body", function() {
                return [
                  _vm.isPlaceholderVisible
                    ? _vm._t("noDataPlaceholder", function() {
                        return [_c("SelectListNoDataPlaceholder")]
                      })
                    : _vm.isSearchPlaceholderVisible
                    ? _vm._t("noResultsPlaceholder", function() {
                        return [
                          _c("SelectListNoResultsPlaceholder", {
                            on: { clear: _vm.onClearSearch }
                          })
                        ]
                      })
                    : _vm._e()
                ]
              })
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function() {
            return [_vm._t("footer")]
          },
          proxy: true
        },
        {
          key: "item",
          fn: function(ref) {
            var item = ref.item
            var onExpand = ref.onExpand
            return [
              _c("TreeAccordionItem", {
                attrs: {
                  item: item,
                  size: _vm.size,
                  multiselect: _vm.multiselect,
                  "selected-nodes": _vm.selectedNodes[item.id],
                  selected: _vm.selectedItems[item.id]
                },
                on: { expand: onExpand, input: _vm.onValueChange }
              })
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }