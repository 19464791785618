var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _vm.isExpanderVisible
        ? _c("TreeAccordionItemArrowExpander", {
            attrs: { "is-expanded": _vm.isExpanded },
            nativeOn: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.onExpand.apply(null, arguments)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }