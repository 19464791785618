var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("NumericBadge", {
    attrs: { number: _vm.badgeNumber, theme: _vm.badgeTheme }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }