var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VerticalFixedScroll", [
    _c(
      "div",
      { staticClass: "tree-designer" },
      [
        _c("Designer", {
          attrs: {
            columns: _vm.columns,
            "row-height": _vm.rowHeight,
            "last-item-row": _vm.localItems.length
          },
          scopedSlots: _vm._u(
            [
              {
                key: "appendBody",
                fn: function(ref) {
                  var layerStyle = ref.layerStyle
                  return [
                    _c(
                      "TreeDesignerDraggableLayer",
                      {
                        style: layerStyle,
                        attrs: {
                          "single-root": _vm.singleRoot,
                          items: _vm.localItems,
                          "hidden-items": _vm.hiddenItems,
                          "children-length": _vm.childrenLength,
                          disabled: _vm.disabled
                        },
                        on: {
                          "update-items": _vm.onUpdateItems,
                          "update-item": _vm.onUpdateItem,
                          "insert-item": _vm.onInsertItem,
                          "add-item": _vm.onAddItem,
                          "remove-item": _vm.onRemoveItem,
                          "remove-items": _vm.onRemoveItems,
                          "shift-items": _vm.onShiftItems,
                          "expand-item": _vm.onExpandItem,
                          "drop-item": _vm.onDropItem
                        }
                      },
                      [
                        _vm.localItems.length === 0
                          ? _c("DesignerPlaceholderItem", {
                              attrs: {
                                gap: _vm.gridGap,
                                "row-height": _vm.rowHeight
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.ghostIndex !== -1
                          ? _c("DesignerGhostItem", {
                              attrs: {
                                row: _vm.ghostIndex.row,
                                column: _vm.ghostIndex.column,
                                gap: _vm.gridGap,
                                "row-height": _vm.rowHeight
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.itemsWithoutGhost, function(item) {
                          return [
                            _vm._t(
                              "item",
                              function() {
                                return [
                                  _c("TreeDesignerItem", {
                                    key: item.id,
                                    attrs: {
                                      item: item,
                                      gap: _vm.gridGap,
                                      "children-length":
                                        _vm.childrenLength[item.id],
                                      disabled: _vm.disabled,
                                      "is-expanded":
                                        typeof _vm.hiddenItems[item.id] ===
                                        "undefined"
                                    },
                                    on: {
                                      "expand-item": _vm.onExpandItem,
                                      "remove-item": _vm.onRemoveItems
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "description",
                                          fn: function() {
                                            return [
                                              _vm._t("itemDescription", null, {
                                                item: item,
                                                childrenLength:
                                                  _vm.childrenLength[item.id]
                                              })
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ]
                              },
                              {
                                item: item,
                                gap: _vm.gridGap,
                                childrenLength: _vm.childrenLength[item.id],
                                disabled: _vm.disabled,
                                isExpanded:
                                  typeof _vm.hiddenItems[item.id] ===
                                  "undefined",
                                onExpandItem: _vm.onExpandItem,
                                onRemoveItems: _vm.onRemoveItems
                              }
                            )
                          ]
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.connectionLines, function(item) {
                          return _c("TreeDesignerConnectionLine", {
                            key:
                              item.id + "-line|" + item.row + "|" + item.column,
                            attrs: {
                              items: _vm.localItems,
                              row: item.row,
                              column: item.column,
                              "row-height": _vm.rowHeight,
                              gap: _vm.gridGap,
                              "border-style":
                                item.id === "ghost_item" ? "dashed" : "solid"
                            }
                          })
                        })
                      ],
                      2
                    )
                  ]
                }
              }
            ],
            null,
            true
          )
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }