var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DesignerItem", {
    attrs: {
      row: _vm.item.row,
      column: _vm.item.column,
      gap: _vm.gap,
      title: _vm.title,
      subtitle: _vm.subtitle,
      disabled: _vm.disabled,
      "menu-items": _vm.menuItems,
      "menu-position": { right: "8px" }
    },
    on: { "select-menu-option": _vm.onSelectValue },
    scopedSlots: _vm._u(
      [
        {
          key: "prepend",
          fn: function() {
            return [
              _vm.hasChildren
                ? _c("IconButton", {
                    attrs: {
                      size: _vm.tinySize,
                      theme: _vm.secondaryPlainTheme
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.onExpandItem.apply(null, arguments)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "icon",
                          fn: function() {
                            return [
                              _c("IconArrowDouble", {
                                attrs: { state: _vm.buttonExpanderIconState }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2679414948
                    )
                  })
                : _vm._e()
            ]
          },
          proxy: true
        },
        {
          key: "description",
          fn: function() {
            return [_vm._t("description")]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }