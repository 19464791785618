var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DesignerDraggableLayer",
    {
      attrs: { draggable: !_vm.disabled },
      nativeOn: {
        dragstart: function($event) {
          return _vm.onDragStart.apply(null, arguments)
        },
        dragend: function($event) {
          return _vm.onDragEnd.apply(null, arguments)
        },
        dragover: function($event) {
          return _vm.onDragOver.apply(null, arguments)
        },
        dragleave: function($event) {
          return _vm.onDragLeave.apply(null, arguments)
        },
        drop: function($event) {
          return _vm.onDrop.apply(null, arguments)
        }
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }